export const PreferenceCheckbox = ({ id, name, label, imgSrc, checked, onChange }) => (
    <div className="control-container">
      <div className="check-container">
        <input id={id} name={name} className="checkbox" type="checkbox" checked={checked} onChange={onChange} />
        <label htmlFor={id} className="check-label">
          <span className="check-status"></span>
          <span className="check-label-text">{label}</span>
          <img src={imgSrc} alt={label} width="300" className="check-img" />
        </label>
      </div>
    </div>
  );