import { useState } from 'react';

const SubscribeOptions = ({ toggleAllPreferences, allSubscribed, noneSubscribed }) => {
  const [isHovered, setIsHovered] = useState({ subAll: false, unsubAll: false });

  return (
    <div className="control-pair-container">
      {/* Subscribe All */}
      <div className="control-container">
        <div className="check-container">
          <input
            type="checkbox"
            id="sub-all-cb"
            className="all-cb"
            onChange={() => toggleAllPreferences(true)}
          />
          <label
            htmlFor="sub-all-cb"
            className="control-all mb-0"
            style={{
              backgroundColor: allSubscribed
                ? 'var(--bg-green)'
                : isHovered.subAll
                ? 'var(--bg-green)'
                : 'var(--bg-grey)',
              color: allSubscribed || isHovered.subAll ? 'white' : 'inherit',
              cursor: 'pointer',
              transition: 'background-color 0.2s',
              padding: '10px',
              borderRadius: '4px',
            }}
            onMouseEnter={() => setIsHovered({ ...isHovered, subAll: true })}
            onMouseLeave={() => setIsHovered({ ...isHovered, subAll: false })}
          >
            Subscribe All
          </label>
        </div>
      </div>

      {/* Unsubscribe All */}
      <div className="control-container">
        <div className="check-container">
          <input
            type="checkbox"
            id="unsub-all-cb"
            className="all-cb"
            onChange={() => toggleAllPreferences(false)}
          />
          <label
            htmlFor="unsub-all-cb"
            className="control-all mb-0"
            style={{
              backgroundColor: noneSubscribed
                ? 'var(--bg-green)'
                : isHovered.unsubAll
                ? 'var(--bg-green)'
                : 'var(--bg-grey)',
              color: noneSubscribed || isHovered.unsubAll ? 'white' : 'inherit',
              cursor: 'pointer',
              transition: 'background-color 0.2s',
              padding: '10px',
              borderRadius: '4px',
            }}
            onMouseEnter={() => setIsHovered({ ...isHovered, unsubAll: true })}
            onMouseLeave={() => setIsHovered({ ...isHovered, unsubAll: false })}
          >
            Unsubscribe All
          </label>
        </div>
      </div>
    </div>
  );
};

export default SubscribeOptions;
