import { useState, useEffect } from 'react';
import { updatePreferences } from '../services/mainPanel.service';
import Confirmation from './Confirmation';
import { FooterComponent } from './FooterComponent';
import SubscribeOptions from './SubscribeOptions';
import { PreferenceCheckbox } from './PreferenceCheckbox';
import hospitalityImage from '../assets/Fusion-Lounge-CO-43.jpg';

const MainPanel = ({ apiData }) => {
  const [preferences, setPreferences] = useState({
    Formula1: false,
    MotoGP: false,
    Festival: false,
    BTCC: false,
    OtherRaceEvents: false,
    TrackDays: false,
    Business: false,
    Drive: false,
    VisitStay: false,
    Museum: false,
    GeneralNewsEvents: false,
    F1Communication: false,
    Hospitality: false,
  });

  const [companyCommunication, setCompanyCommunication] = useState(false);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [brand, setBrand] = useState('');
  const [submitted, setSubmitted] = useState(false);

  // Ensures only the current keys from the above preferences state are considered.
  const validPreferenceKeys = Object.keys(preferences).filter((key) => key !== 'F1Communication');
  const allSubscribed = validPreferenceKeys.every((key) => preferences[key]);
  const noneSubscribed = validPreferenceKeys.every((key) => !preferences[key]);

  useEffect(() => {
    if (apiData) {
      setPreferencesAPI(apiData);
    }
  }, [apiData]);

  // Dynamically update Company Communication state based on preferences
  useEffect(() => {
    const isAnyPreferenceChecked = validPreferenceKeys.some((key) => preferences[key]); // Ensure F1Communication is excluded from "allSubscribed" and "noneSubscribed"
    setCompanyCommunication(isAnyPreferenceChecked);
  }, [preferences]);

  const setPreferencesAPI = (apiData) => {
    if (apiData.Token) setToken(apiData.Token);
    if (apiData.Email) setEmail(apiData.Email);
    if (apiData.Brand) setBrand(apiData.Brand);

    const newPreferences = { ...preferences }; // Start with the current state

    if (apiData.Preferences) {
      // Update preferences from API response
      for (const [key, value] of Object.entries(apiData.Preferences.Interests || {})) {
        if (key in preferences) {
          newPreferences[key] = value === 'Y';
        }
      }

      // Handle ThirdParty preferences (e.g., F1Communication)
      if ('F1Communication' in preferences) {
        newPreferences.F1Communication = apiData.Preferences.ThirdParty?.F1Communication === 'Y';
      }
    }

    // Default any preferences not in the API to `false`
    for (const key of Object.keys(preferences)) {
      if (!(key in newPreferences)) {
        newPreferences[key] = false;
      }
    }

    setPreferences(newPreferences);
  };

  const togglePreference = (key) => {
    setPreferences((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const toggleAllPreferences = (checked) => {
    const updatedPreferences = validPreferenceKeys.reduce((acc, key) => {
      acc[key] = checked;
      return acc;
    }, {});
    setPreferences((prev) => ({ ...prev, ...updatedPreferences }));
  };

  const handleAddPreference = async (event) => {
    event.preventDefault();
    const payload = {
      token,
      email,
      brand,
      date_stamp: new Date().toISOString(),
      Preferences: {
        Email: { 'Company Communication': companyCommunication ? 'Y' : 'N' },
        Interests: Object.fromEntries(
          Object.entries(preferences)
            .filter(([key]) => key !== 'F1Communication') // Exclude F1Communication
            .map(([key, value]) => [key, value ? 'Y' : 'N']) // Convert boolean to 'Y'/'N'
        ),
        ThirdParty: {
          F1Communication: preferences.F1Communication ? 'Y' : 'N',
        },
      },
    };

    // console.log('payload', payload);
    await updatePreferences(payload);
    setSubmitted(true);
  };

  const preferenceConfig = [
    {
      id: 'f1-cb',
      name: 'Formula1',
      label: 'Formula 1',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-formula-1.jpg',
    },
    {
      id: 'motogp-cb',
      name: 'MotoGP',
      label: 'MotoGP',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-motogp.jpg',
    },
    {
      id: 'festival-cb',
      name: 'Festival',
      label: 'Silverstone Festival',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-festival.jpg',
    },
    {
      id: 'btcc-cb',
      name: 'BTCC',
      label: 'BTCC',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-btcc.jpg',
    },
    {
      id: 'other-race-events-cb',
      name: 'OtherRaceEvents',
      label: 'Other Race Events',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-other-events.jpg',
    },
    {
      id: 'track-days-cb',
      name: 'TrackDays',
      label: 'Track Days',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-trackdays.jpg',
    },
    {
      id: 'business-cb',
      name: 'Business',
      label: 'Business',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-business.jpg',
    },
    {
      id: 'drive-cb',
      name: 'Drive',
      label: 'Drive Experiences',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-drive.jpg',
    },
    {
      id: 'visit-stay-cb',
      name: 'VisitStay',
      label: 'Visit & Stay',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-visit.jpg',
    },
    {
      id: 'museum-cb',
      name: 'Museum',
      label: 'Museum',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-museum.jpg',
    },
    {
      id: 'general-news-events-cb',
      name: 'GeneralNewsEvents',
      label: 'General News',
      imgSrc: 'https://go.silverstone.co.uk/prefCentre/hostedAssets/images/option-ss-generalnews.jpg',
    },
    {
      id: 'hospitality-cb',
      name: 'Hospitality',
      label: 'Hospitality',
      imgSrc: hospitalityImage,
    },
  ];

  return (
    <div>
      {submitted ? (
        <Confirmation />
      ) : (
        <form onSubmit={handleAddPreference}>
          <div className="col-max-width logo-container">
            <a href="https://www.silverstone.co.uk/">
              <svg
                enableBackground="new 0 0 248 37.7"
                viewBox="0 0 248 37.7"
                xmlns="http://www.w3.org/2000/svg"
                width="248"
                className="logo-image"
              >
                <path
                  d="m129.6 22-4 11.8-4-11.8h-5l5.5 15.4h6.7l5.5-15.4zm27.9 2.5.1-2.5h-4.6v15.4h4.6v-8.3c0-2.2.8-2.9 2.9-2.9h3.3v-4.2h-1.2c-2.6 0-3.9.9-5.1 2.5zm-18.7 3.6c.5-1.9 1.8-2.7 4.1-2.7 2.1 0 3.4 1 3.8 2.7zm4-6.4c-4.3 0-8.9 2.3-8.9 8s4.8 8 9.2 8c2.8 0 5.6-.6 7.6-2.7l-2.4-2.9c-1.3 1.1-2.3 1.8-5.2 1.8-2.5 0-4-1.1-4.4-2.9h12.6c0-.4.1-.9.1-1.3 0-5.5-4.2-8-8.6-8m31.3 6.1-2.6-.1c-1.7-.1-2.4-.3-2.4-1 0-1 1.6-1.3 3.6-1.3 2.6 0 3.8.7 4.8 1.7l2.8-2.8c-1.7-1.7-3.9-2.5-7.5-2.5-4.7 0-8.1 1.8-8.1 5 0 3.5 3.2 4.5 6.7 4.7l2.3.1c1.7.1 2.4.2 2.4 1 0 1.1-1.7 1.5-3.7 1.5-2.7 0-4.2-.7-5.4-1.9l-2.8 2.8c1.5 1.9 4.1 2.7 8.1 2.7 4.7 0 8.2-1.9 8.2-5.3s-2.8-4.4-6.4-4.6zm61.3.3c.5-1.9 1.8-2.7 4.1-2.7 2.1 0 3.4 1 3.8 2.7zm12.6 1.6c0-5.5-4.1-8-8.6-8-4.3 0-8.9 2.3-8.9 8s4.8 8 9.2 8c2.8 0 5.6-.6 7.6-2.7l-2.4-2.9c-1.3 1.1-2.3 1.8-5.2 1.8-2.5 0-4-1.1-4.4-2.9h12.6c.1-.4.1-.9.1-1.3zm-60.2-10.9h-4.6v3.2h-2.1v3.9h2.1v6.5c0 3.6 1.7 4.9 4.9 4.9h4.2v-3.9h-2.7c-1.3 0-1.8-.4-1.8-1.7v-5.8h4.5v-3.9h-4.5zm13.9 14.8c-2.8 0-4.2-1.5-4.2-3.9s1.4-3.9 4.2-3.9 4.2 1.5 4.2 3.9-1.4 3.9-4.2 3.9zm0-11.9c-4.3 0-8.9 2.3-8.9 8s4.6 8 8.9 8 8.9-2.3 8.9-8-4.6-8-8.9-8zm20.8 0c-2.6 0-4.7 1.1-5.7 2.6l.1-2.3h-4.7v15.4h4.6v-8.1c0-2.4 1.2-3.4 4.1-3.4 2.4 0 3.5 1 3.5 3.4v8.1h4.6v-9.1c-.1-4.2-2.2-6.6-6.5-6.6m-195.1 15.7h6.8l41-20.5h-6.8zm27.3-20.5-41 20.5h6.8l41-20.5zm56.7 20.5h4.6v-20.5h-4.6zm-36.8-37.4-74.6 37.4h6.8l74.7-37.4zm-6.6 0h-20.8l-40.9 20.5h20.5zm36.5 20.4h4.6v-3.4h-4.6zm-10.4 4.6-3.7-.2c-2.5-.1-4.1-.3-4.1-1.6s1.9-2.2 5.5-2.2c3.9 0 5.3.6 6.6 2.2l3.9-2.5c-1.9-2.8-5.4-4-10.2-4-5.3 0-10.7 1.9-10.7 6.6 0 4.3 3.5 5.7 8.7 5.9l3.7.2c2.5.1 3.9.3 3.9 1.7s-2.2 2.3-5.5 2.3c-4.2 0-6.1-.7-7.4-2.9l-3.9 2.5c2.2 3.7 5.3 4.7 11.1 4.7 5.3 0 10.6-1.9 10.6-6.7 0-4.5-3.4-5.8-8.5-6m10.4 12.4h4.6v-15.4h-4.6z"
                  fill="#120531"
                />
              </svg>
            </a>
          </div>

          <section className="col-max-width section-container">
            <header>
              <h1>
                <span className="text-royal">DON'T PUT US IN</span> <br className="mob-hide" />
                THE REAR VIEW
              </h1>
            </header>
            <p>We want to stay in touch, let us know what you'd like to hear about below.</p>
          </section>

          <section className="col-100p bg-grey">
            <div className="col-max-width section-container">
              <header>
                <h2>I want to hear from Silverstone about:</h2>
                <p>Your preferences are below, just tap the images to let us know what you would like to hear about.</p>
              </header>
              <div className="control-pair-container control-pair-3 events-controls cb-group">
                {preferenceConfig.map(({ id, name, label, imgSrc }) => (
                  <PreferenceCheckbox
                    key={id}
                    id={id}
                    name={name}
                    label={label}
                    imgSrc={imgSrc}
                    checked={preferences[name]}
                    onChange={() => togglePreference(name)}
                  />
                ))}
              </div>
            </div>
          </section>

          <section className="col-100p">
            <div className="col-max-width section-container sub-unsub-all-container">
              <SubscribeOptions
                toggleAllPreferences={toggleAllPreferences}
                allSubscribed={allSubscribed}
                noneSubscribed={noneSubscribed}
              />
            </div>
          </section>

          <section className="col-100p bg-grey">
            <div className="col-max-width section-container">
              <div className="control-container center-control control-wider">
                <div className="check-container">
                  <input
                    type="checkbox"
                    id="f1-sub"
                    className="all-cb"
                    checked={preferences.F1Communication}
                    onChange={() => togglePreference('F1Communication')}
                    aria-describedby="f1-privacy-policy"
                  />
                  <label htmlFor="f1-sub" className="control-all mb-0">
                    I want to receive the latest information from F1<sup>®</sup> by email, including relevant news,
                    surveys, offers, and exclusive competitions.
                  </label>
                </div>
              </div>
              <p id="f1-privacy-policy">
                <a href="https://account.formula1.com/#/en/privacy-policy" target="_blank" rel="noopener noreferrer">
                  F1 privacy policy
                </a>
              </p>
            </div>
          </section>

          <section className="col-100p">
            <div className="col-max-width section-container">
              <header>
                <p>Changes to communication preferences may take up to 24 hours to take effect.</p>
              </header>
              <button type="submit" className="submit-btn btn-primary">
                Submit
              </button>
            </div>
          </section>

          <FooterComponent />
        </form>
      )}
    </div>
  );
};

export default MainPanel;
